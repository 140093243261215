import { ReactNode } from 'react';
import Head from 'next/head';
import { Box } from '@mui/material';

import Header from './header';
import AsideMenu from './asideMenu';
import Main from './main';
import Footer from './footer';

interface LayoutProps {
  children: ReactNode;
  pageTitle: string;
  userName?: string;
  userLogout?: () => void;
  drawerWidth?: number;
  asideMenuOptions?: {
    title: string;
    path?: string;
    icon: ReactNode;
  }[];
  noPageTitleHeader?: boolean;
  backgroundHeader?: string;
  customMainStyle?: object;
  customHeaderStyle?: object;
  customUserIconStyle?: object;
}

export function Layout({
  children,
  pageTitle,
  userName,
  userLogout,
  drawerWidth,
  asideMenuOptions,
  noPageTitleHeader,
  backgroundHeader,
  customMainStyle = {},
  customHeaderStyle = {},
  customUserIconStyle = {},
}: LayoutProps) {
  const defaultDrawerWidth = asideMenuOptions
    ? asideMenuOptions.length > 0
      ? 150
      : 0
    : 0;
  const PageTitleHeader = noPageTitleHeader ? '' : pageTitle;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <Header
        pageTitle={PageTitleHeader}
        userName={userName}
        userLogout={userLogout}
        backgroundHeader={backgroundHeader}
        customStyle={customHeaderStyle}
        customUserIconStyle={customUserIconStyle}
      />
      <AsideMenu
        defaultDrawerWidth={defaultDrawerWidth}
        drawerWidth={drawerWidth}
        asideMenuOptions={asideMenuOptions}
      />
      <Main
        customStyle={customMainStyle}
        drawerWidth={drawerWidth || defaultDrawerWidth}
      >
        {children}
      </Main>
      <Footer />
    </Box>
  );
}
