import { AppBar, Toolbar, Typography, Grid, IconButton } from '@mui/material';
import { useRouter } from 'next/router';

import theme from '../../styles/theme';

import { CompanyLogo } from '../company-logo';

import { useWindowSize } from '../../hooks/use-window-size';

import { MenuWrapper } from './buttons/app-bar-buttons';
import { ExitToApp, PersonOutlineOutlined } from '@mui/icons-material';

interface HeaderProps {
  pageTitle: string;
  userName?: string;
  backgroundHeader?: string;
  customStyle?: object;
  userLogout?: () => void;
  customUserIconStyle?: object;
}

function Header({
  pageTitle,
  userName,
  userLogout,
  customStyle = {},
  customUserIconStyle = {},
}: HeaderProps) {
  const { opacityScroll } = useWindowSize();
  const router = useRouter();

  return (
    <AppBar
      elevation={0}
      sx={{
        opacity: `${opacityScroll}`,
        zIndex: 10,
        ...customStyle,
      }}
    >
      <Toolbar>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <Grid item>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ cursor: 'pointer', zIndex: 10 }}
              onClick={() => router.push('/')}
            >
              <Grid item>
                <CompanyLogo height="50px" width="50px" />
              </Grid>
              <Grid item sx={{ display: { xs: 'none', sm: 'flex' } }}>
                <Typography
                  sx={{
                    color: theme.palette.common.white,
                    fontWeight: 500,
                  }}
                  variant="h4"
                  component="h1"
                >
                  {pageTitle}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {opacityScroll > 0 && (
            <Grid item>
              <MenuWrapper
                menuItems={[
                  {
                    label: userName || 'Usuario',
                    icon: PersonOutlineOutlined,
                  },
                  {
                    label: 'Cerrar sesión',
                    icon: ExitToApp,
                    onClick: userLogout,
                  },
                ]}
              >
                <IconButton
                  size="large"
                  sx={{
                    color: theme.palette.common.white,
                    ...customUserIconStyle,
                  }}
                >
                  <PersonOutlineOutlined fontSize="large" />
                </IconButton>
              </MenuWrapper>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
